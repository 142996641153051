// request.js
import axios from "axios";
import store from '../src/store/index.js'
import Cookies from 'js-cookie'
// axios.defaults.withCredentials=true;
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers['Remember-Token'] = Cookies.get('rememberToken');
// axios.defaults.headers['Remember-Token'] = localStorage.getItem('rememberToken');
const BASE_API = store.state.httpUrl;
window.BASE_API = BASE_API;
let i = 0;
// axios.interceptors.request.use(
// 	config => {
// 		if (localStorage.getItem('rememberToken')) {
// 			config.headers.RememberToken = localStorage.getItem('rememberToken');
// 		}

// 		return config;
// 	},
// 	error => {
// 		return Promise.reject(error);
// 	});
//定义方法
axios.interceptors.response.use(
	config => {
		// console.log(config);
		if (config.data.status == "401" && i == 0) {
			i = 1;
			alert(config.data.message);
			Cookies.remove("rememberToken");
			Cookies.remove("userinfo");
			window.location.href = '/';
			setTimeout(function () {
				i = 0
			}, 2000);
			return false;
		}
		return config;
	}
);
export function post(url, params) {
	return axios.post(BASE_API + url, this.$qs.stringify(params)); //如果不需要转json的话，那么第二次参数就是params
}
export function get(url, params) {
	return axios.get(BASE_API + url, {
		params
	});
}