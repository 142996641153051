import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		component: () => import('../views/login/index.vue'),
		redirect: '/login',
		children: [{
			path: '/login',
			component: () => import('../views/login/index.vue'),
		}, ]
	},
	{
		path: '/index',
		component: () => import('../views/index/index.vue'),
	},
	{
		path: '/statistics',
		component: () => import('../views/statistics/index.vue'),
	},
	{
		path: '/administrators',
		component: () => import('../views/administrators/index.vue'),
	},
	{
		path: '/administratorsadd',
		component: () => import('../views/administrators/add.vue'),
	},
	{
		path: '/administratorsedit',
		name: 'administratorsedit',
		component: () => import('../views/administrators/edit.vue'),
	},
	{
		path: '/knowledge',
		component: () => import('../views/knowledge/index.vue'),
	},
	{
		path: '/knowledgeadd',
		component: () => import('../views/knowledge/add.vue'),
	},

	{
		path: '/knowledgeedit',
		name: 'knowledgeedit',
		component: () => import('../views/knowledge/edit.vue'),
	},
	{
		path: '/knowledgelist',
		component: () => import('../views/knowledge/list/index.vue'),
	},
	{
		path: '/knowledgelistadd',
		component: () => import('../views/knowledge/list/add.vue'),
	},
	{
		path: '/knowledgelistedit',
		name: 'knowledgelistedit',
		component: () => import('../views/knowledge/list/edit.vue'),
	},
	{
		path: '/commodity',
		name: 'commodity',
		component: () => import('../views/commodity/index.vue'),
	},
	{
		path: '/classification',
		name: 'classification',
		component: () => import('../views/commodity/classification/index.vue'),
	},
	{
		path: '/examine',
		name: 'examine',
		component: () => import('../views/commodity/examine.vue'),
	},
	{
		path: '/classificationadd',
		name: 'classificationadd',
		component: () => import('../views/commodity/classification/add.vue'),
	},
	{
		path: '/classificationedit',
		name: 'classificationedit',
		component: () => import('../views/commodity/classification/edit.vue'),
	},
	{
		path: '/sensor',
		name: 'sensor',
		component: () => import('../views/sensor/index.vue'),
	},
	{
		path: '/sensoredit',
		name: 'sensoredit',
		component: () => import('../views/sensor/edit.vue'),
	},
	{
		path: '/sensoreditcode',
		name: 'sensoreditcode',
		component: () => import('../views/sensor/editCode.vue'),
	},
	{
		path: '/orderinfo',
		name: 'orderinfo',
		component: () => import('../views/sensor/orderinfo.vue'),
	},
	{
		path: '/idleearning',
		name: 'idleearning',
		component: () => import('../views/Idleearning/index.vue'),
	},
	{
		path: '/idleearninginfo',
		name: 'idleearninginfo',
		component: () => import('../views/Idleearning/info.vue'),
	},
	{
		path: '/partner',
		name: 'partner',
		component: () => import('../views/Idleearning/partner/index.vue'),
	},
	{
		path: '/subordinate',
		name: 'subordinate',
		component: () => import('../views/Idleearning/partner/subordinate.vue'),
	},
	{
		path: '/idleearningorder',
		name: 'idleearningorder',
		component: () => import('../views/Idleearning/idleearningorder/index.vue'),
	},
	{
		path: '/setup',
		name: 'setup',
		component: () => import('../views/setup/index.vue'),
	},
	{
		path: '/poster',
		name: 'poster',
		component: () => import('../views/setup/poster/index.vue'),
	},
	{
		path: '/proportion',
		name: 'proportion',
		component: () => import('../views/setup/proportion/index.vue'),
	},
	{
		path: '/reject',
		name: 'reject',
		component: () => import('../views/setup/reject/index.vue'),
	},
	{
		path: '/customer',
		name: 'customer',
		component: () => import('../views/setup/customer/index.vue'),
	},
	{
		path: '/user',
		name: 'user',
		component: () => import('../views/user/index.vue'),
	},
	{
		path: '/userEdit',
		name: 'userEdit',
		component: () => import('../views/user/edit.vue'),
	},
	{
		path: '/userinfo',
		name: 'userinfo',
		component: () => import('../views/user/info.vue'),
	},
	{
		path: '/userRole',
		name: 'userRole',
		component: () => import('../views/user/role/role.vue'),
	},
	{
		path: '/withdrawal',
		name: 'withdrawal',
		component: () => import('../views/user/withdrawal/index.vue'),
	},
	{
		path: '/withdrawalinfo',
		name: 'withdrawalinfo',
		component: () => import('../views/user/withdrawal/info.vue'),
	},
	{
		path: '/balance',
		name: 'balance',
		component: () => import('../views/user/balance/index.vue'),
	},
	{
		path: '/filemanagement',
		name: 'filemanagement',
		component: () => import('../views/filemanagement/index.vue'),
	},
	{
		path: '/statisticsedit',
		name: 'statisticsedit',
		component: () => import('../views/statistics/edit.vue'),
	},
	{
		path: '/linertype',
		name: 'linertype',
		component: () => import('../views/statistics/liner/type.vue'),
	},
	{
		path: '/linertypeedit',
		name: 'linertypeedit',
		component: () => import('../views/statistics/liner/edit.vue'),
	},
	{
		path: '/mine',
		name: 'mine',
		component: () => import('../views/mine/index.vue'),
	},
	{
		path: '/mineedit',
		name: 'mineedit',
		component: () => import('../views/mine/edit.vue'),
	},
	{
		path: '/minetype',
		name: 'minetype',
		component: () => import('../views/mine/type/list.vue'),
	},
	{
		path: '/minetypeedit',
		name: 'minetypeedit',
		component: () => import('../views/mine/type/edit.vue'),
	},
	{
		path: '/minefile',
		name: 'minefile',
		component: () => import('../views/mine/file/list.vue'),
	},
	{
		path: '/minefileedit',
		name: 'minefileedit',
		component: () => import('../views/mine/file/edit.vue'),
	},
	{
		path: '/minefilepackage',
		name: 'minefilepackage',
		component: () => import('../views/mine/file/package.vue'),
	},
	{
		path: '/mill',
		name: 'mill',
		component: () => import('../views/mill/index.vue'),
	},
	{
		path: '/milledit',
		name: 'milledit',
		component: () => import('../views/mill/edit.vue'),
	},
	{
		path: '/millDiameter',
		name: 'millDiameter',
		component: () => import('../views/millDiameter/index.vue'),
	},
	{
		path: '/millDiameteredit',
		name: 'millDiameteredit',
		component: () => import('../views/millDiameter/edit.vue'),
	},
	{
		path: '/message',
		name: 'message',
		component: () => import('../views/message/list.vue'),
	},
	{
		path: '/messageinfo',
		name: 'messageinfo',
		component: () => import('../views/message/info.vue'),
	},
	{
		path: '/company',
		name: 'company',
		component: () => import('../views/company/list.vue'),
	},
	{
		path: '/companyedit',
		name: 'companyedit',
		component: () => import('../views/company/edit.vue'),
	},
	{
		path: '/product',
		name: 'product',
		component: () => import('../views/product/list.vue'),
	},
	{
		path: '/productedit',
		name: 'productedit',
		component: () => import('../views/product/edit.vue'),
	},
	{
		path: '/producttype',
		name: 'producttype',
		component: () => import('../views/product/type/list.vue'),
	},
	{
		path: '/producttypeedit',
		name: 'producttypeedit',
		component: () => import('../views/product/type/edit.vue'),
	},
	{
		path: '/literature',
		name: 'literature',
		component: () => import('../views/literature/list.vue'),
	},
	{
		path: '/literatureedit',
		name: 'literatureedit',
		component: () => import('../views/literature/edit.vue'),
	},
	{
		path: '/literaturetype',
		name: 'literaturetype',
		component: () => import('../views/literature/type/list.vue'),
	},
	{
		path: '/literaturetypeedit',
		name: 'literaturetypeedit',
		component: () => import('../views/literature/type/edit.vue'),
	},
	{
		path: '/standard',
		name: 'standard',
		component: () => import('../views/standard/list.vue'),
	},
	{
		path: '/standardedit',
		name: 'standardedit',
		component: () => import('../views/standard/edit.vue'),
	},
	{
		path: '/standardtype',
		name: 'standardtype',
		component: () => import('../views/standard/type/list.vue'),
	},
	{
		path: '/standardtypeedit',
		name: 'standardtypeedit',
		component: () => import('../views/standard/type/edit.vue'),
	},
	{
		path: '/patent',
		name: 'patent',
		component: () => import('../views/patent/list.vue'),
	},
	{
		path: '/patentedit',
		name: 'patentedit',
		component: () => import('../views/patent/edit.vue'),
	},
	{
		path: '/patenttype',
		name: 'patenttype',
		component: () => import('../views/patent/type/list.vue'),
	},
	{
		path: '/patenttypeedit',
		name: 'patenttypeedit',
		component: () => import('../views/patent/type/edit.vue'),
	},
	//
]

const router = new VueRouter({
	// mode: 'history',
	// base: process.env.BASE_URL,
	routes
})

export default router
