<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => (this.isRouterAlive = true));
    },
  },
};
</script>
<style lang="scss">
@import "../common/iconfont/iconfont.css";
body {
  margin: 0 0;
  /* background-color: #F2F2F2; */
}
.container {
  min-width: 1580px;
}
.jimaiBtn {
  border-radius: 7px;
  padding: 10px 30px;
  background-image: linear-gradient(to bottom right, red, rgb(10, 10, 10));
  color: white;
  margin-left: 30px;
  display: inline-block;
}
.chushouBtn {
  border-radius: 7px;
  padding: 10px 30px;
  background-image: linear-gradient(
    to bottom right,
    rgb(0, 255, 85),
    rgb(10, 10, 10)
  );
  color: white;
  margin-left: 30px;
  display: inline-block;
}
.ml-50 {
  margin-left: 50px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.txt-red {
  color: red;
}
.txt-green {
  color: green;
}
.violet {
  background-image: linear-gradient(to bottom right, #9b58c7, #c245d7);
}

.blue {
  background-image: linear-gradient(to bottom right, #39a5da, #54bbc1);
}

.yellow {
  background-image: linear-gradient(to bottom right, #f29825, #ecc71b);
}

.orange {
  background-image: linear-gradient(to bottom right, #fb2f94, #ff7a7f);
}
.bottom-paging {
  margin-top: 30px;
  text-align: right;
}
</style>
