import Vue from 'vue'
import App from './App.vue'
import router from './router'
import cookie from 'vue-cookie'
import VueCookies from 'vue-cookies'
import store from './store'
import axios from 'axios'
import qs from 'qs'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
  get,
  post
} from "../utils/request.js";
Vue.prototype.$qs = qs;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$axios = axios;
Vue.prototype.$store = store;
Vue.config.productionTip = false
Vue.prototype.$cookie = cookie;
Vue.use(VueCookies);
Vue.use(ElementUI);
Vue.use(VueQuillEditor);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
